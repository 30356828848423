<template>
    <iframe :src="decodeURIComponent($route.params.url)" width="100%" height="100%" style="border:none;" />
</template>
<script>
export default {
    name:'MvcView',
    created(){
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        window.addEventListener("message", (event) => {
            if(event.data.goToVue)
            {
                this.$router.push(event.data.path);
            }
            else
            {
                this.$router.push({
                    name: "mvc",
                    params: { url: event.data.url }
                });
            }
            
        }, false);
    },
    //por las
    mounted(){
        document.getElementsByTagName('html')[0].style.overflow = 'hidden';
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    },
    beforeDestroy(){
        document.getElementsByTagName('html')[0].style.overflow = '';
    }
}
</script>

<style scoped>

</style>